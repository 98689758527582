import React from "react";
import {Card} from "antd";
import { Link } from "gatsby";
import {ArrowRightOutlined} from '@ant-design/icons';

import trafficImg from '../../images/services/Traffic Tickets.png';
import ServiceTemplateCascade from "./ServiceTemplateCascade";
import Routes from "../../constant/routes";

const Items = [
  {key: 1, title: 'How much does a traffic ticket cost in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">If you just pay the ticket, you are pleading guilty to the violation. In Missouri, the fine range is between $50 and $150, but could be more depending upon other factors, including the violation, your driving record, and the court. <strong>TicketTamer can fix your ticket for $75 in most cases, and you won’t have to go to court.</strong>
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    If you just pay the ticket, you are pleading guilty to the violation. In Missouri, the fine range is between $50 and $150, but could be more depending upon other factors, including the violation, your driving record, and the court.
    <br/><br/>
    {/* <a href="https://www.courts.mo.gov/page.jsp?id=310" className="Link" target="_blank" rel="noreferrer">Click here for state and local court fine information. <ArrowRightOutlined /></a>
    <br/><br/> */}
    You’ll also have to pay court costs, which range from $25 to $75, plus hundreds, even thousands more for your insurance – for the next 3-5 years --because insurers will use the ticket to increase your rates.
    <br/><br/>
    {/* <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer"> Click here for more information on tickets and insurance.  <ArrowRightOutlined /></a>
    <br/><br/> */}
    You’ll also put your license at risk of suspension or even revocation if you already have tickets on your record or get any more.
    <br/><br/>
    {/* <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link" target="_blank" rel="noreferrer">Click here for more on license suspension and revocation. <ArrowRightOutlined /></a>
    <br/><br/> */}
    This is the most expensive way to handle your ticket.<strong>TicketTamer can keep your insurance rates down by fixing your ticket. We will negotiate with the prosecutor to reduce the ticket to a non-moving or no point violation so your record will stay clean and your insurance rates won’t go up – and you won’t have to go to court.</strong>
    </div>
    
  </div>},
  {key: 2, title: 'What you absolutely need to know before you pay your traffic ticket.', preview: <div>
    <div className="BodyGreyLeft mb16">There are five things you need to know before you just pay your ticket. Compare and see why 40 percent of TicketTamer cases are referrals or satisfied returning clients.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    There are five things you need to know before you just pay your ticket.
    <br/>
    <br/>

    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    You’ll be pleading guilty to the violation, and it will be on your record forever.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    You’ll get at least 2-4 points on your driving record in point system states, including Missouri. Your insurance company will use these points to increase your rates hundreds, even thousands, of dollars for the next 3-5 years. This is why just paying the ticket is the most expensive way to handle your ticket.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    If you get too many points, your license will be suspended or even revoked. In Missouri, your license will be suspended for up to 90 days if you have 8 points in 18 months. It will be revoked if you have 12 points in 12 months or 18 points in 24 months.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    The points will drop off your record after 3 years, but the violation will stay on forever and insurance companies can use this to raise your rates or even deny you coverage.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    You can avoid all of this by hiring TicketTamer to fix your ticket. We will get the charge reduced down to a non-moving or no point violation, which will keep your record clean and insurance rates down– and you won’t have to go to court.
    </Card>
    </div>
    </div>},
  {key: 3, title: 'How many points will I have on my license if I plead guilty and pay my court fine for a traffic ticket without hiring a traffic ticket lawyer?', preview: <div>
    <div className="BodyGreyLeft mb16">If you just pay the ticket, you will get at least 2-4 points on your driving record in point system states, including Missouri. Your insurance company will use these points to increase your rates hundreds, even thousands, of dollars for the next 3-5 years. <strong>TicketTamer can fix your ticket for $75 in most cases, and you won’t have to go to court. </strong>
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    If you just pay the ticket, you will get at least 2-4 points on your driving record in point system states, including Missouri. Your insurance company will use these points to increase your rates hundreds, even thousands, of dollars for the next 3-5 years.
    <br/><br/>
    {/* <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link" target="_blank" rel="noreferrer">Click here for Missouri’s point system. <ArrowRightOutlined /></a>
    <br/><br/> */}
    <u><i><strong>This is why just paying the ticket is the most expensive way to handle your ticket.</strong></i></u>
    <br/><br/>
    And by the way, those points will also put your license at risk of suspension or even revocation. In Missouri, your license will be suspended for up to 90 days if you have 8 points in 18 months. It will be revoked if you have 12 points in 12 months or 18 points in 24 months.
    <br/><br/>
    {/* <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link" target="_blank" rel="noreferrer">Click here for more on license suspension and revocation.  <ArrowRightOutlined /></a>
    <br/><br/> */}
    You can avoid all of this by hiring TicketTamer to fix your ticket. We will get the charge reduced down to a non-moving or no point violation, which will keep your record clean and insurance rates down – and you won’t have to goto court.
    </div>
    
  </div>},
  {key: 4, title: 'Why should I hire a traffic ticket lawyer to fix my ticket?', preview: <div>
    <div className="BodyGreyLeft mb16">Just paying your ticket is the worst thing you can do. It will put points on your record for 3 years and result in an insurance increase of hundreds, if not thousands of dollars a year -- for 3-5 years, depending upon the state and your insurer! <strong>TicketTamer can fix your ticket for $75 in most cases, and you won’t have to go to court.</strong>
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Just paying your ticket is the worst thing you can do. It will put points on your record for 3 years and result in an insurance increase of hundreds, if not thousands of dollars a year -- for 3-5 years, depending upon the state and your insurer!
    <br/><br/>
    {/* <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer"> Click here for more information on tickets and insurance.  <ArrowRightOutlined /></a>
    <br/><br/> */}
    It will also put your license at risk of suspension or even revocation. In Missouri, your license will be suspended for up to 90 days if you have 8 points in 18 months. It will be revoked if you have 12 points in 12 months or 18 points in 24 months.
    <br/><br/>
    {/* <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link" target="_blank" rel="noreferrer">Click here for Missouri’s point system. <ArrowRightOutlined /></a>
    <br/><br/> */}
    You can avoid all of this by hiring a lawyer to fix your ticket – and the lawyers at TicketTamer are the best. Our success rate is 97%, but we are more proud that 40% of our cases are referrals or satisfied returning clients. We know the law and the courts, and will get the charge reduced down to a non-moving or no point violation– and you won’t have to go to court.
    </div>
    
  </div>},
  {key: 5, title: 'How much does a traffic ticket lawyer cost in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">The cost of a speeding ticket lawyer in Missouri will vary depending upon a lot of factors, including the speed limit, how fast you were going, your driving record, and the court. TicketTamer charges just $75 for a basic traffic ticket, and you won’t have to go to court.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    The cost of a speeding ticket lawyer in Missouri will vary depending upon a lot of factors, including the speed limit, how fast you were going, your driving record, and the court. TicketTamer charges just $75 for a basic traffic ticket, and you won’t have to go to court.
    <br/><br/>
    Click here for the five questions you should ask before hiring a lawyer to fix your traffic ticket.
    </div>
    
  </div>},
  {key: 6, title: 'What questions should you ask before you hire an attorney to fix your ticket?', preview: <div>
    <div className="BodyGreyLeft mb16">Before hiring an attorney to fix a traffic ticket, you should ask the lawyer 5 questions. Compare and see why 40 percent of TicketTamer cases are referrals or returning satisfied clients.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Before hiring an attorney to fix a traffic ticket, you should ask these five questions:
    <br/>
    <br/>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    <strong>Have you fixed traffic tickets in this court before?</strong>
    <br/> 
    If the answer is yes, that’s good, of course. If the answer is no, consider getting another lawyer who has worked in that court before. It may not make a difference, but then again it may and you don’t want to take any chances.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    <strong>How much will it cost to fix my ticket?</strong>
    <br/> 
    The cost for legal services to fix a ticket can vary on a number of factors, such as what the ticket is for and what court the ticket is in. A good lawyer should be able to give you an accurate quote quickly if you provide this information. If they don’t, move on.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    <strong>Will the fee include court fine and court costs?</strong>
    <br/> 
    Some lawyers include the fine and court costs within their fees, some do not. The answer to this question may explain why you are getting very different quotes.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    <strong>How will you keep me informed of developments on my case?</strong>
    <br/> 
    Good lawyers take special care to keep you updated on your case. Bad lawyers will leave you wondering what will go on, and will probably try to avoid this question by simply reassuring you that they will keep in touch with them. Ask the lawyer how he or she will do that?
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    <strong>What kind of support will I have if I have to pay a fine?</strong>
    <br/> 
    If the lawyer fee includes your fine, you won’t need to worry about this question. If not, be sure to ask if they will help you make you get your fine paid correctly. A good lawyer will provide detailed instructions for payment and may be willing to pay it for you for a fee.
    </Card>
    </div>
  </div>},
  {key: 7, title: 'How much will my insurance rates increase if I plead guilty and pay my court fine?', preview: <div>
    <div className="BodyGreyLeft mb16">It depends on your situation, but hundreds, even thousands of dollars every year for 3-5 years. TicketTamer charges just $75 for a basic traffic ticket to prevent this, and you won’t have to go to court.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    It depends on your situation, including where you live, how many points you already have, and whether you have younger drivers on your policy.
    <br/><br/>
    {/* <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer">Click here for more information on insurance and tickets. <ArrowRightOutlined /></a>
    <br/><br/> */}
    Here is what we can say for certain: When you get a ticket, you will lose any good driver discount you may have, and be slapped with a surcharge for the ticket. The amount will vary, but in our experience, just one ticket will increase your rates hundreds, even thousands of dollars a year – for 3-5 years.
    <br/><br/>
    <strong>TicketTamer can help you avoid this by negotiating with the prosecutor to reduce your ticket down to a non-moving or no-point violation. Our success rate is 97%, but we are more proud that 40% of our cases are referrals or satisfied returning clients. We know the law and the courts, and will get the charge reduced down to a non-moving or no point violation, and you won’t have to go to court.</strong>
    </div>
    
  </div>},
  {key: 8, title: 'How long does traffic ticket stay on record in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">Your traffic ticket will stay on your record forever, and the points for that ticket will stay on your record for 3-5 years. This will increase your insurance hundreds, even thousands, of dollars each year for 3-5 years. <strong>TicketTamer  can prevent this for just $75 for a basic traffic ticket, and you won’t have to go to court. </strong>
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Your traffic ticket will stay on your record forever, and the points for that ticket will stay on your record for 3-5 years.
    <br/><br/>
    {/* <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link" target="_blank" rel="noreferrer">Click here for Missouri’s point system. <ArrowRightOutlined /></a>
    <br/><br/> */}
    Even when the points fall off of your record, the speeding violation will stay on your record forever. Because the violation is still on your record, insurance companies will charge you higher rates for 3-5 years, depending upon the insurer and the state.
    <br/><br/>
    {/* <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer"> Click here for more information on tickets and insurance.  <ArrowRightOutlined /></a>
    <br/><br/> */}
    <strong>TicketTamer can keep your record clean and insurance rates down by negotiating with the prosecutor to reduce your ticket to a non-moving or no-point violation, and you won’t have to go to court.</strong>
    </div>
    
  </div>},
  {key: 9, title: 'What are ticket fighters and where do I find a ticket fighter to fight my ticket?', preview: <div>
    <div className="BodyGreyLeft mb16">What are ticket fighters and where do I find a ticket fighter to fight my ticket?Ticket fighters are lawyers who fight traffic tickets by negotiating with prosecutors to reduce the charges down to a non-moving or no point violation to keep your record clean and insurance rates down. TicketTamer’s lawyers have a 97 percent success rate, and only charge $75 for a basic traffic ticket – and you won’t have to go to court.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Ticket fighters are lawyers who fight traffic tickets by negotiating with prosecutors to reduce the charges down to a non-moving or no point violation to keep your record clean and insurance rates down.
    <br/><br/>
    The lawyers at TicketTamer are the best. Our success rate is 97%, but we are more proud that 40% of our cases are referrals or satisfied returning clients. We know the law and the courts, and will get the charge reduced down to a non-moving or no point violation – and you won’t have to go to court
    </div>
    
  </div>},
  {key: 10, title: 'What are ticket fixers and where do I find a ticket fixer to fight my ticket?', preview: <div>
    <div className="BodyGreyLeft mb16">Ticket fixers are lawyers who fight traffic tickets by negotiating with prosecutors to reduce the charges down to a non-moving or no point violation to keep your record clean and insurance rates down. TicketTamer’s lawyers have a 97 percent success rate, and only charge $75 for a basic traffic ticket – and you won’t have to go to court.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Ticket fixers are lawyers who fight traffic tickets by negotiating with prosecutors to reduce the charges down to a non-moving or no point violation to keep your record clean and insurance rates down.
    <br/><br/>
    The lawyers at TicketTamer are the best. Our success rate is 97%, but we are more proud that 40% of our cases are referrals or satisfied returning clients. We know the law and the courts, and will get the charge reduced down to a non-moving or no point violation – and you won’t have to go to court.
    </div>
    
  </div>},
];

const TrafficTicketLawyers = (props) => {
  return <ServiceTemplateCascade 
  title="Traffic Ticket Lawyers" 
  url="submit-traffic-ticket"
  items={Items}
  image={trafficImg}
  metaDescription="Submit your traffic ticket  - Get your quote instantly - Schedule your free consultation"
  />;
}

export default TrafficTicketLawyers;
